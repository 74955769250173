import { Controller } from 'stimulus'

/*
  This handles the pages panel UI which communicates to the stimulusJS controllers
  found within it's children

  This is done by exposing the controllers by defining #controller to the DOM
*/
export default class extends Controller {
  static targets = [
    'checklistSubmitButton',
  ]

  connect(){
    this._openByAnchor()
  }

  // Any activity will unlock the submit button
  checklistChanged(_evt) {
    if(this.hasChecklistSubmitButtonTarget)
      this.checklistSubmitButtonTarget?.removeAttribute('disabled')
  }

  // WIP: TODO: Move to another controller?
  imageProofsDownload(evt){
    const el = evt.target

    evt.preventDefault()
    el.innerText = el.dataset.startText

    setTimeout(function(){
      el.innerText = el.dataset.readyText
      el.classList.remove('-ghost')
      el.classList.add('-green')
    }, 1000)
  }

  openImageProofingPopup(){
    document.querySelector(
      '[data-controller="job-checklist"]'
    )?.checklistController.openImageProofingPopup()
  }

  sendEmail(evt){
    evt.preventDefault()

    // UX: The email form is located at the top
    window.scrollTo({top: 0, behavior: 'smooth'})

    this._firstTabs()
    this._secondTabs()

    const email = evt.currentTarget.getAttribute('data-email')
    const self  = this

    // UX: Delaying helps the user focus where the the email form is
    setTimeout(function(){
      self._tagifyObject().addTags(email)
    }, 350)
  }

  _tagifyObject(){
    return document.querySelector('.input.communication_recipients section.o2v-tagify').tagify
  }

  _firstTabs(){
    return document.querySelector('#tabs-communications').controller.openTo(0)
  }

  _secondTabs(){
    return document.querySelector('#tabs-send').controller.openTo(0)
  }

  // Open the <details> element
  _openByAnchor(){
    const anchor = location.hash

    if(anchor && anchor.length) {
      let el = document.querySelector(anchor)
      if(el) el.setAttribute('open', 'open')
    }
  }
}