import { Controller } from 'stimulus'

// Contains multiple classes. The Plugin classes and a single Controller
// Plugin controllers use the controller instance through #controller and
// have methods that controller will call. ie. #actionButtonClick which returns
// true or false. These booleans dictate if the form is submitted.

// If a new plugin is required a example class structure is below:
// Remember to add a constant (ie PLUGIN_EXAMPLE)
/*
class ExamplePlugin {
  #controller

  constructor(controller){
    this.#controller = controller
  }

  // Current action that supports popups is "invoiced"
  actionButtonClick(){ return true } // or false

  // All buttons are enabled by default
  updateMenuUI(){}

  processRow(row){ _toggleJobRow(this.isRowEnabled(row), row) }

  // All buttons are enabled by default
  isRowEnabled(_row){ return true }
}
*/

// These are defined within the HTML data (data-action-type)
const PLUGIN_CHECKLIST  = 'action-checklist-item'
const PLUGIN_ACCOUNTING = 'action-accounting'

const _toggleDisable = function(el, toggle){
  toggle ? el.removeAttribute('disabled') : el.setAttribute('disabled', 'disabled')
}

const _toggleJobRow = function(enabled, jobRow){
  jobRow.closest('tr').classList.toggle('matches-selected-action', enabled)
  if(jobRow.checked) jobRow.checked = enabled
  jobRow.disabled = !enabled
}

// User only if they have the accounting feature enabled
class AccountingPlugin {
  #controller

  constructor(controller){
    this.#log('Init')

    this.#controller = controller
  }

  // Current action that supports popups is "invoiced"
  actionButtonClick(){
    // The caller would only call this on the specific plugin
    // so this is just a precaution
    if(this.#controller.selectedActionType != PLUGIN_ACCOUNTING) return false

    const action        = this.#controller.selectedValue
    const actionPopupEl = this.#actionPopupEl(action)

    if(actionPopupEl) {
      this.#log(`#openPopup for:${action}`)
      this.#updatePopupForm(actionPopupEl)
      actionPopupEl.popupController.open()
      return false
    } else {
      console.warn('TODO: continue form submission')
      return true
    }
  }

  // All buttons are enabled by default
  updateMenuUI(){}

  processRow(row){
    _toggleJobRow(this.isRowEnabled(row), row)
  }

  // All buttons are enabled by default
  isRowEnabled(_row){
    return true
  }

  #actionPopupEl(action){
    return document.querySelector(`#popup-row-select-${action}-popup`)
  }

  #updatePopupForm(actionPopupEl){
    this.#log('Clearing current and populating popup job_ids input')

    actionPopupEl.querySelectorAll('.popup-input-job-id').forEach(el => el.remove())

    actionPopupEl.querySelector('[name="job_action[selected_value]"]').value = this.#controller.selectedValue

    this.#controller.rowIds.forEach(jobId => {
      actionPopupEl.querySelector('form').append(this.#createJobIdsInput(jobId))
    })
  }

  #createJobIdsInput(jobId){
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'job_action[job_ids][]')
    input.classList.add('popup-input-job-id')
    input.value = jobId
    return input
  }

  #log() { console.debug('[D][AccountingPlugin]', ...arguments) }
}

// Used within the exported controller class below
class ChecklistPlugin {
  #controller

  constructor(controller){
    this.#log('Init')
    this.#controller = controller
  }

  actionButtonClick(){
    // The caller would only call this on the specific plugin
    // so this is just a precaution
    return this.#controller.selectedActionType == PLUGIN_CHECKLIST
  }

  updateMenuUI(){
    this.#toggleSelectOptions()
  }

  // Only enable rows if:
  // - The user is the correct operator for the checklist task
  // - The row's task ID matches the one selected from the drop down
  //
  // This code also places CSS class to help identify to the user it's selectable
  //
  processRow(row){
    _toggleJobRow(this.isRowEnabled(row), row)
  }

  isRowEnabled(row){
    const operatorAccess = row.dataset.nextChecklistItemAccess == 'true'
    const itemId         = row.dataset.nextChecklistItemId
    const sameId         = (itemId == this.#controller.selectedValue)

    return sameId && operatorAccess
  }

  // triggered on page load (initialize) that disables buttons that have no
  // use because no jobs are present for that specific task
  #toggleSelectOptions(){
    this.#log('#toggleSelectOptions: checking which options are possible based on jobs in list')

    this.#ownSelectOptions.forEach(option => {
      _toggleDisable(option, this.#checklistItemAvailable(option.value))
    })
  }

  #checklistItemAvailable(itemId){
    if(!itemId) return false

    const idPresent = this.#enabledChecklistItemIds.indexOf(itemId.toString()) != -1
    return idPresent
  }

  get #enabledChecklistItemIds(){
    return this.#controller.rowTargets
      .filter(el => el.dataset.nextChecklistItemAccess == 'true')
      .map(el => el.dataset.nextChecklistItemId)
  }

  get #ownSelectOptions(){
    return Array.from(this.#controller.selectedOptions).filter(option => {
      return option.dataset.actionType == PLUGIN_CHECKLIST
    })
  }

  #log()  { console.debug('[D][ChecklistPlugin]', ...arguments) }
}

// Used in two different ways
// 1) Basic select rows and click action
//
// 2) Select action, select rows, and click continue button
//    This longer process allows JS to check which jobs are allowed
//    to be selected
//
export default class extends Controller {
  // Plugin picked based on which option is selected from the drop down
  // (See #selectedPlugin)
  //
  #checklistPlugin
  #accountingPlugin

  static targets = [
    'row',                             // Job row
    'toggleCheckbox',                  // Button to toggle on/off each job's checkbox
    'actionDropdown',  'actionButton', // Main UI components
  ]

  initialize(){
    this.#log('Setting up plugins')
    this.#checklistPlugin  = new ChecklistPlugin(this)
    this.#accountingPlugin = new AccountingPlugin(this)

    this.#updateUI()
  }

  connect(){}

  /* -------------------------------------------------------------------------*/

  actionButtonClick(evt){
    evt.preventDefault()

    this.#log('action button clicked')

    if(this.#selectedPlugin?.actionButtonClick()) {
      if(confirm(`Proceed to ${this.#selectedOptionName}`)){
        this.#postRequest()
      } else {
        this.#log('User rejected confirmation')
      }
    } else {
      this.#log(`plugin returned false (#actionButtonClick, ${this.selectedActionType})`)
    }
  }

  // User selects a option from the drop down menu we'll want to preset if
  // a multi button is present
  actionDropboxChange(evt){
    this.#enableRows()
    this.#updateUI()
  }

  // The single checkbox in the table header that unchecks or checks all
  // jobs check boxes (unless restricted)
  //
  // Only mark items checked if they're part of the action selected
  //
  toggleCheckbox(evt){
    this.#log('#multiSelect: enable/disable row checkboxes and enabling action button')

    this.rowTargets.forEach(checkbox => {
      if (checkbox.disabled)
        checkbox.checked = false
      else if(this.hasMultiContinueButtonTarget)
        checkbox.checked = evt.target.checked && this.#isRowActive(checkbox)
      else
        checkbox.checked = evt.target.checked
    })

    this.#updateUI()
  }

  rowSelect(_evt){
    this.#log(`#rowSelect called by event`)
    this.#updateUI()
  }

  /* -------------------------------------------------------------------------*/

  get selectedValue(){
    return this.#selectedOption.value
  }

  get selectedActionType() {
    return this.#selectedOption?.dataset.actionType
  }

  get selectedOptions(){
    return this.actionDropdownTarget.children
  }

  get rowIds(){
    return this.rowTargets.filter(i => i.checked ).map(i => i.value)
  }

  /* -------------------------------------------------------------------------*/

  // If nothing is selected from the action then active
  // but if a presetActionIdent is set we'll need to check
  #isRowActive(row){
    return !!this.#selectedPlugin?.isRowEnabled(row)
  }

  // HTTP request to JobActionsController
  #postRequest(){
    const jobAction = this.selectedActionType
    if(!jobAction) throw 'No job action supplied!'

    const jobIds = this.rowIds
    if(!jobIds.length) throw 'No job IDs to send!'

    o2v.flashMessagesReset()

    const jsonData = JSON.stringify({
      job_action: {job_ids: jobIds, selected_value: this.selectedValue}
    })

    this.#log(`PUTing: ${this.#controllerActionPath(jobAction)} with:${jsonData}`)

    // TODO: ClientUser and User URLs are different. Place as HTML data
    fetch(this.#controllerActionPath(jobAction), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': this.#csrfToken
      },
      body: jsonData
    })
    .then( resp => {
      if(resp.status >= 500) {
        this.#warn(`Failed to submit job action`)
        return this.#jsonFor5xxError(resp)
      } else {
        return resp.json()
      }
    })
    .then(respJson => {
      if(respJson.success){
        this.#resetJobCheckboxes()
        this.#updateUI()
        o2v.notice('Successfully updated. The page will reload soon...')
        setTimeout(() => { location.reload() }, 1000);
      } else {
        this.#resetJobCheckboxes()
        this.#updateUI()
        this.#warn('Invalid job(s) returned', respJson)
        this.#handleInvalidJobs(respJson)
      }
    }).catch(jsonErr => {
      this.#resetJobCheckboxes()
      this.#updateUI()
      this.#warn('POSTing action failed with', jsonErr)
    })
  }

  // The controller errors using HTML so intercept it and return JSON that's
  // in the structure this controller expects
  #jsonFor5xxError(resp){
    return {success: false, errors: [{messages: [
      `Server returned: ${resp.status}. Tech has been notified`
    ]}]}
  }

  #handleInvalidJobs(jsonErr){
    jsonErr.errors.forEach(json => {
      if(json.job_id)
        o2v.error(`Failed to save job ${json.job_id}: ${json.messages.join(', ')}`)
      else
        o2v.error(json.messages.join(', '))
    })
  }

  // Specific plugin can handle the rows if one matches the data-action-type
  // defined on the 'option' element
  //
  #enableRows(enable){
    if(!enable) enable = true

    const plugin = this.#selectedPlugin

    this.#log(`enableRows:${enable} (plugin: ${!!plugin})`)

    if (plugin) {
      this.rowTargets.forEach(el => plugin.processRow(el, enable) )
    } else {
      // Do nothing, if no plugin was selected it's like the black select option
    }
  }

  #resetJobCheckboxes(){
    this.#log('Unchecking all checkboxes')
    this.rowTargets.forEach(i => i.checked = false)
  }

  // Using what's selected to enable/disable components
  #updateUI(){
    const selectedJobs   = this.#hasSelectedRows
    const selectedOption = !!this.selectedValue

    this.#log(`enable/disable UI components (${this.#inspectSelection})`)

    _toggleDisable(this.actionButtonTarget, selectedJobs && selectedOption)

    this.#log(`Trigger each plugins #updateUI methods`)
    this.#checklistPlugin.updateMenuUI()
    this.#accountingPlugin.updateMenuUI()

    if(!this.selectedActionType) {
      this.#enableToggleCheckboxes(false)
      this.#disableAllJobCheckboxes()
    } else {
      this.#enableToggleCheckboxes(true)
    }
  }

  // See JobsHelper#row_select_jobs_action_path for placeholder
  #controllerActionPath(jobAction){
    if(!jobAction) throw 'Job action is required to create path'

    return this.element.dataset.rowSelectUrl.replace(':JOB_ACTION', jobAction)
  }

  #enableToggleCheckboxes(enable){
    if(!enable) this.toggleCheckboxTarget.checked = false
    _toggleDisable(this.toggleCheckboxTarget, enable)
  }

  #disableAllJobCheckboxes(){
    this.#log('Disabling all job checkboxes')
    this.rowTargets.forEach(row => _toggleJobRow(false, row) )
  }

  get #selectedOptionName(){
    return this.#selectedOption.innerText.trim()
  }

  get #selectedPlugin(){
    switch (this.selectedActionType) {
      case PLUGIN_CHECKLIST:
        return this.#checklistPlugin
      case PLUGIN_ACCOUNTING:
        return this.#accountingPlugin
      default:
        return null
    }
  }

  get #csrfToken(){
    return document.querySelector('[name="csrf-token"]').content
  }

  get #selectedOption() {
    return this.actionDropdownTarget.children[this.actionDropdownTarget.selectedIndex]
  }

  get #hasSelectedRows(){
    return !!this.rowIds.length
  }

  get #inspectSelection() {
    return `selectedValue:${this.selectedValue} selectedActionType:${this.selectedActionType}`
  }

  #log()  { console.debug('[D][RowSelectController]', ...arguments) }
  #warn() { console.warn('[W][RowSelectController]', ...arguments) }
}